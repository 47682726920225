export function setArticlePraise(articleId) {
  var praiseArticle = JSON.parse(localStorage.getItem("praiseArticle"));
  if (praiseArticle == null) {
    praiseArticle = [];
    praiseArticle.push(articleId);
  } else if (praiseArticle.indexOf(articleId) == -1) {
    praiseArticle.push(articleId);
  }
  localStorage.setItem("praiseArticle", JSON.stringify(praiseArticle));
}

export function getArticlePraise(articleId) {
  var praiseArticle = JSON.parse(localStorage.getItem("praiseArticle"));
  if (praiseArticle != null && praiseArticle.indexOf(articleId) != -1) {
    return false;
  }
  return true;
}

export function setDynamicPraise(dynamicId) {
  var praiseDynamic = JSON.parse(localStorage.getItem("praiseDynamic"));
  if (praiseDynamic == null) {
    praiseDynamic = [];
    praiseDynamic.push(dynamicId);
  } else if (praiseDynamic.indexOf(dynamicId) == -1) {
    praiseDynamic.push(dynamicId);
  }
  localStorage.setItem("praiseDynamic", JSON.stringify(praiseDynamic));
}

export function getDynamicPraise(dynamicId) {
  var praiseDynamic = JSON.parse(localStorage.getItem("praiseDynamic"));
  if (praiseDynamic != null && praiseDynamic.indexOf(dynamicId) != -1) {
    return false;
  }
  return true;
}

export function setGuestMessagePraise(messageId) {
  var praiseGuestMessage = JSON.parse(
    localStorage.getItem("praiseGuestMessage")
  );
  if (praiseGuestMessage == null) {
    praiseGuestMessage = [];
    praiseGuestMessage.push(messageId);
  } else if (praiseGuestMessage.indexOf(messageId) == -1) {
    praiseGuestMessage.push(messageId);
  }
  localStorage.setItem(
    "praiseGuestMessage",
    JSON.stringify(praiseGuestMessage)
  );
}

export function getGuestMessagePraise(messageId) {
  var praiseGuestMessage = JSON.parse(
    localStorage.getItem("praiseGuestMessage")
  );
  if (
    praiseGuestMessage != null &&
    praiseGuestMessage.indexOf(messageId) != -1
  ) {
    return false;
  }
  return true;
}

export function setCommentPraise(articleId, commentId) {
  var article = JSON.parse(localStorage.getItem("praiseArticleComment"));
  if (article == null) {
    article = {};
  }
  if (article[articleId] == null) {
    let comment = [];
    comment.push(commentId);
    article[articleId] = comment;
  } else if (article[articleId].indexOf(commentId) == -1) {
    article[articleId].push(commentId);
  }
  localStorage.setItem("praiseArticleComment", JSON.stringify(article));
}

export function getCommentPraise(articleId, commentId) {
  var article = JSON.parse(localStorage.getItem("praiseArticleComment"));
  if (article != null && article[articleId].indexOf(commentId) != -1) {
    return false;
  }
  return true;
}
